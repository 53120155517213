import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/sign-profile/mutations';
import {getField, updateField} from 'vuex-map-fields';
import axios from "axios";
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    topic: {},
    api: ConstantAPI.signProfile,
    visible: false,
    action: null,
    listOfficer: []
});

const actions = {
    async sign({state, commit}, payload) {
        commit('setLoading', true)
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.SIGN, payload);
            checkResponse(response, () => {
                isSuccess = true;
            });
            return isSuccess;
        } catch (err) {
            console.log(err);
        } finally {
            commit('setLoading', false)
        }
    },
    async signMps({commit}, payload) {
        commit('setLoading', true)
        try {
            let payloadUpload = null
            const response = await axios.post('https://localhost:4567/signmultiPDFv2', payload.listFile, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                payloadUpload = response.data.result.map(e => ({
                    byteBase: e.fileContent,
                    hsttId: payload.listFileId.filter(i => i.fileName === e.fileName)[0].id,
                    maSoHs: payload.listFileId.filter(i => i.fileName === e.fileName)[0].maSoHs,
                }))
                return payloadUpload
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Ký không thành công',
                    duration: 4,
                });
            }
            return payloadUpload;
        } catch (err) {
            console.log(err);
        } finally {
            commit('setLoading', false)
        }
    },
    async uploadSignMps({state, commit}, payload) {
        commit('setLoading', true)
        try {
            let payloadSign = null
            const response = await DataService.callApi(state.api.UPLOAD_SIGN_MPS,
                {datas: payload});
            checkResponse(response, () => {
                payloadSign = response.data
            });
            return payloadSign
        } catch (err) {
            console.log(err);
        } finally {
            commit('setLoading', false)
        }
    },
    async review({state, commit}, payload) {
        commit('setLoading', true)
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.REVIEW, { id: payload });
            checkResponse(response, () => {
                isSuccess = true;
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGES_0046,
                    duration: 4,
                });
            });
            setTimeout(() => {
                commit('setLoading', false)
            }, 1000)
            return isSuccess;
        } catch (err) {
            commit('setLoading', false)
        }
    },
    async transfer({state, commit}, payload) {
        commit('setLoading', true)
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.TRANSFER, payload);
            checkResponse(response, () => {
                isSuccess = true;
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGES_0047,
                    duration: 4,
                });
            });
            setTimeout(() => {
                commit('setLoading', false)
            }, 1000)
            return isSuccess;
        } catch (err) {
            commit('setLoading', false)
        }
    },
    async getListOfficer({ state }, payload) {
        try {
            const response = await DataService.callApi(state.api.LIST_OFFICER, payload);
            checkResponse(response, () => {
                state.listOfficer = response.data || []
            });
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
