import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import mutations from './mutations';
import { notification } from 'ant-design-vue';
import { MESSAGES } from '@/util/message-notification';
import {checkResponse} from "@/util/common-utils";

const state = () => ({
  loading: false,
  api: ConstantAPI.dashboard.processingStatus,
  dataTable: null,
  dataChart: null,
  dataToTal: [],
  listCanBo: [],
});

const actions = {
  async export({ state }, params) {
    try {
      state.loading = true;
      const response = await DataService.getConfig(
        state.api.EXPORT.url,
        params,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `thong-ke-tinh-trang-xlhs-${Date.now()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: MESSAGES.MESSAGES_0032,
        duration: 4,
      });
      state.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
  async getTotalProfile({commit, state}, params) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.GET_TOTAL_PROFILE, null, params);
      checkResponse(response, () => {
        state.dataToTal = response.data ? [response.data] : []
      });
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async getAllCanBo({ state }, params) {
    try {
      const response = await DataService.callApi(
          state.api.GET_ALL_CAN_BO,
          null,
          params
      );
      checkResponse(response, () => {
        state.listCanBo = response.data || []
      });
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
