import axios from 'axios';
import { checkResponseAsync, formatDate } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import store from '@/vuex/store';
import Cookies from 'js-cookie';
import ConstantAPI from '@/config/ConstantAPI';

const API_ENDPOINT = window.VUE_APP_API_ENDPOINT;
const USERNAME = window.VUE_APP_AUTH_CLIENT;
const PASSWORD = window.VUE_APP_AUTH_SECRET;
const BASIC_AUTH = `Basic ${btoa(USERNAME + ':' + PASSWORD)}`;

const httpClient = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Accept-language': 'vi'
  },
  withCredentials: true,
});

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
let originalFormData = null;

httpClient.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const { headers } = config;
  if (/[\w]*\/oauth\/token/g.test(config.url)) {
    config.headers = { ...headers, Authorization: BASIC_AUTH };
  } else {
    config.headers = {
      ...headers,
    };
  }
  Object.keys(config.params || {}).forEach((k) => {
    const date = config.params[k];
    if (date instanceof Date) {
      config.params[k] = formatDate(date, 'yyyy-MM-dd HH:mm:ss');
    }
  });
  if (config.data instanceof FormData) {
    // Lưu trữ form data ban đầu
    originalFormData = config.data;
  }
  return config;
});

let refreshing = false;

httpClient.interceptors.response.use(
    (response) => response.data,
    async (error) => {
      const config = error.config;
      if (/[\w]*\/oauth\/token/g.test(config.url) || config.retry) {
        return Promise.reject(error.response.data);
      }
      if (error.response) {
        let errorMessage =
            error.response.data === null
                ? 'Ngoại lệ hệ thống nội bộ, vui lòng liên hệ với quản trị trang web'
                : error.response.data.message;
        switch (error.response.status) {
          case 404:
            notification.error({
              message: 'Thông báo',
              description: 'Xin lỗi, tài nguyên không được tìm thấy',
              duration: 4,
            });
            break;
          case 403:
            notification.error({
              message: 'Thông báo',
              description: 'Tài khoản không có quyền truy cập tài nguyên',
              duration: 4,
            });
            break;
          case 401:
            try {
              // Sử dụng lại form data ban đầu (nếu có)
              if (originalFormData) {
                config.data = originalFormData;
                originalFormData = null;
              }
              if (refreshing && !config.retry) {
                for (let i = 0; i < 10; i++) {
                  await new Promise((r) => setTimeout(r, 100));
                  if (!refreshing) break;
                }
                const newConfig = {
                  ...config,
                  data: config.data, // Giữ nguyên dữ liệu đầu vào ban đầu
                };
                if (config.data instanceof FormData) {
                  newConfig.headers['Content-Type'] = 'multipart/form-data';
                }
                return httpClient(newConfig);
              }
              config.retry = true;
              refreshing = true;
              const response = await DataService.callApi(
                  ConstantAPI.auth.REFRESH_TOKEN,
                  {
                    code: `lavie?p0nhu-uu-bb${new Date().getTime()}`
                  },
                  null,
                  {
                    Authorization: BASIC_AUTH,
                  }
              );
              checkResponseAsync(
                  response,
                  async () => {
                    store.commit('setToken', response.data.accessToken);
                    Cookies.set('token', response.data.accessToken);
                  },
                  async () => {
                    await store.dispatch('logOut');
                    refreshing = false;
                    return Promise.reject(response);
                  }
              );
              refreshing = false;
              const newConfig = {
                ...config,
                data: config.data, // Giữ nguyên dữ liệu đầu vào ban đầu
              };
              if (config.data instanceof FormData) {
                newConfig.headers['Content-Type'] = 'multipart/form-data';
              }
              return httpClient(newConfig);
            } catch (_error) {
              await store.dispatch('logOut');
              refreshing = false;
              return Promise.reject(_error);
            }
          default:
            if (
                error.request.responseType === 'blob' &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf('json') !== -1
            ) {
              let errorString = await error.response.data.text();
              notification.error({
                message: 'Thông báo',
                description: JSON.parse(errorString).message,
                duration: 4,
              });
              break;
            }
            notification.error({
              message: 'Thông báo',
              description: errorMessage,
              duration: 4,
            });
            break;
        }
      }
      return Promise.reject(
          error.response ? error.response.data : error.response
      );
    }
);

class DataService {
  static callApi(api, data, params, headers) {
    return httpClient({
      method: api.method,
      url: api.url,
      data,
      params,
      headers,
    });
  }

  static upload(api, data, config) {
    return httpClient({
      method: api.method,
      url: api.url,
      data,
      ...config,
    });
  }

  static getConfig(path = '', params, headers, responseType) {
    return httpClient.get(path, { headers, params, responseType });
  }

  static get(path = '', params, headers) {
    return httpClient.get(path, { headers, params });
  }

  static post(path = '', data = {}, headers) {
    return httpClient.post(path, data, { headers });
  }

  static patch(path = '', data = {}, headers, params, responseType) {
    return httpClient.patch(path, data, {headers, params, responseType});
  }

  static delete(path = '', data = {}, headers) {
    return httpClient.delete(path, data, { headers });
  }

  static put(path = '', data = {}, headers) {
    return httpClient.put(path, data, { headers });
  }
}

export { DataService };
