import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/file-approval/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {KEY_REQUEST_ID_APPROVAL, PERCENT_APPROVAL} from "@/util/common-constant";
// import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    topic: {},
    api: ConstantAPI.fileApproval,
    visible: false,
    action: null,
    percent: 0,
    requestId: null,
    totalProcess: false,
    numberProcess: 0,
    listLanhDao: []
});

const actions = {
    async approval({state}, payload) {
        let formPayload = null
        if (payload) {
            formPayload = {
                duyetHs: payload.data.map(e => ({
                    dongY: e.dongY,
                    ids: e.hsttId,
                    noiDung: e.noiDung,
                    ghiChu: e.ghiChu || '',
                })),
                lanhDaoDuyet: payload.lanhDaoDuyet || ''
            }
        }
        localStorage.removeItem(KEY_REQUEST_ID_APPROVAL)
        state.percent = 1
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.APPROVAL, formPayload);
            checkResponse(response, () => {
                state.requestId = response.data.requestId
                localStorage.setItem(KEY_REQUEST_ID_APPROVAL, state.requestId)
                if (localStorage.getItem(PERCENT_APPROVAL)) {
                    state.percent = Number(localStorage.getItem(PERCENT_APPROVAL))
                }
                isSuccess = true;
            });
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async getAllLanhDao({state}) {
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.GET_ALL_LANH_DAO);
            checkResponse(response, () => {
                state.listLanhDao = response.data
                isSuccess = true;
            })
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async syncProfile({state}, payload) {
        try {
            state.loading = true
            let isSuccess = false
            const response = await DataService.callApi(
                ConstantAPI.profile.SYNC,
                {
                    maSoHoso: payload
                }
            );
            checkResponse(response, () => {
                isSuccess = true;
            })
            state.loading = false
            return isSuccess
        } catch (err) {
            console.log(err);
        }
    },
    setProcess({state}, percent) {
        state.percent = percent
    },
    resetProcess({state}) {
        state.requestId = null
        state.percent = 0
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
