<template>
  <a-row>
    <a-col :span="24">
      <div class="flex items-center justify-end gap-2">
        <cds-button
          v-if="permissions.hasReset"
          class="mt-1 sDash_form-action__btn"
          html-type="button"
          type="light"
          size="small"
          @click="emitEvent('onClear')"
        >
          Làm mới
        </cds-button>
        <cds-button
          v-if="permissions.hasSearch"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onSearch')"
          :disabled="disabledHasSearch"
        >
          Tìm kiếm
        </cds-button>
        <cds-button
          v-if="permissions.hasCreate"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onCreate')"
        >
          Thêm mới
        </cds-button>
        <cds-button
          v-if="permissions.hasCreateMenu"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onCreateMenu')"
        >
          Thêm mới menu
        </cds-button>
        <cds-button
          v-if="permissions.hasCreateButton"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onCreateButton')"
        >
          Thêm mới nút
        </cds-button>
        <a-spin :spinning="loadingExportExcel">
          <cds-button
              v-if="permissions.hasExportExcel"
              class="mt-1 sDash_form-action__btn"
              type="primary"
              size="small"
              @click="emitEvent('onExportExcel')"
              :loading="true"
          >
            Xuất Excel
          </cds-button>
        </a-spin>
        <cds-button
          v-if="permissions.hasApproval"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onApproval')"
        >
          Duyệt
        </cds-button>
        <cds-button
          v-if="permissions.hasCancelApproval"
          class="mt-1 sDash_form-action__btn"
          type="danger"
          size="small"
          @click="emitEvent('onCancelApproval')"
        >
          Bỏ duyệt
        </cds-button>
        <cds-button
          v-if="permissions.hasDelete"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          outlined
          size="small"
          @click="emitEvent('onDelete')"
        >
          Xoá
        </cds-button>
        <cds-button
          v-if="permissions.hasUnPublic"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          outlined
          size="small"
          @click="emitEvent('onUnPublic')"
        >
          Gỡ bài
        </cds-button>
        <cds-button
          v-if="permissions.hasImport"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          outlined
          size="small"
          @click="emitEvent('onImport')"
        >
          Thêm dữ liệu
        </cds-button>
        <cds-button
            v-if="permissions.hasTransfer"
            class="mt-1 sDash_form-action__btn"
            type="primary"
            size="small"
            @click="emitEvent('onTransfer')"
        >
          Chuyển ký
        </cds-button>
        <cds-button
            v-if="permissions.hasReview"
            class="mt-1 sDash_form-action__btn"
            type="primary"
            size="small"
            @click="emitEvent('onReview')"
        >
          Duyệt lại
        </cds-button>
        <cds-button
          v-if="permissions.hasSign"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onSign')"
        >
          Ký
        </cds-button>
        <cds-button
            v-if="permissions.hasSign"
            class="mt-1 mr-10 sDash_form-action__btn"
            type="primary"
            size="small"
            @click="emitEvent('onSignMps')"
        >
          Ký Offline
        </cds-button>
        <cds-button
          v-if="permissions.hasVisaCancel"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onVisaCancel')"
        >
          Hủy thị thực
        </cds-button>
        <cds-button
          v-if="permissions.hasPrint"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onPrint')"
          outlined
        >
          In
        </cds-button>
        <cds-button
          v-if="permissions.hasFastPropose && !isShowHandle"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onFastPropose')"
        >
          Đề xuất nhanh
        </cds-button>
        <cds-button
          v-if="permissions.hasPropose && !isShowHandle"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onPropose')"
        >
          Đề xuất
        </cds-button>
        <cds-button
            v-if="permissions.hasHandle && isShowHandle"
            class="mt-1 sDash_form-action__btn"
            type="primary"
            size="small"
            @click="emitEvent('onHandle')"
        >
          Xử lý
        </cds-button>
        <cds-button
          v-if="permissions.hasExportPdf"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onExportPdf')"
        >
          Xuất PDF
        </cds-button>
        <cds-button
          v-if="permissions.hasBlock"
          class="mt-1 sDash_form-action__btn"
          type="primary"
          size="small"
          @click="emitEvent('onBlock')"
        >
          Chặn
        </cds-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent, watch, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'SearchFormButton',
  props: {
    buttons: VueTypes.array.def([]),
    disabledHasSearch: VueTypes.bool.def(false),
    isShowHandle: VueTypes.bool.def(false),
    loadingExportExcel: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const perms = computed(() => state.auth.userInfo.permissions);
    const hasButton = (button) =>
      (props.buttons || []).some(
        (e) =>
          e.type === button &&
          (!e.permission || (perms.value || []).includes(e.permission))
      );
    const permissions = ref({
      hasReset: false,
      hasSearch: false,
      hasCreate: false,
      hasCreateMenu: false,
      hasCreateButton: false,
      hasDelete: false,
      hasApproval: false,
      hasCancelApproval: false,
      hasExportExcel: false,
      hasUnPublic: false,
      hasImport: false,
      hasReassignment: false,
      hasTransfer: false,
      hasReview: false,
      hasSign: false,
      hasVisaCancel: false,
      hasPropose: false,
      hasHandle: false,
      hasPrint: false,
      hasExportPdf: false,
      hasFastPropose: false,
      hasBlock: false,
    });
    const fetchPerm = () => {
      permissions.value = {
        hasReset: hasButton('RESET'),
        hasSearch: hasButton('SEARCH'),
        hasCreate: hasButton('CREATE'),
        hasCreateMenu: hasButton('CREATE_MENU'),
        hasCreateButton: hasButton('CREATE_BUTTON'),
        hasDelete: hasButton('DELETE'),
        hasApproval: hasButton('APPROVAL'),
        hasCancelApproval: hasButton('CANCEL_APPROVAL'),
        hasExportExcel: hasButton('EXPORT_EXCEL'),
        hasUnPublic: hasButton('UN_PUBLISH'),
        hasImport: hasButton('IMPORT'),
        hasReassignment: hasButton('REASSIGNMENT'),
        hasTransfer: hasButton('TRANSFER'),
        hasReview: hasButton('REVIEW'),
        hasSign: hasButton('SIGN'),
        hasVisaCancel: hasButton('VISA_CANCEL'),
        hasPropose: hasButton('PROPOSE'),
        hasPrint: hasButton('PRINT'),
        hasExportPdf: hasButton('EXPORT_PDF'),
        hasFastPropose: hasButton('FAST_PROPOSE'),
        hasBlock: hasButton('BLOCK'),
        hasHandle: hasButton('HANDLE'),
      };
    };
    watch(() => perms.value, fetchPerm);
    watch(() => props.buttons, fetchPerm);
    onMounted(fetchPerm);
    return {
      emitEvent,
      permissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.sDash_form-action {
  &__btn {
    @apply uppercase;
  }
}
</style>
