import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import mutations from './mutations';
import { notification } from 'ant-design-vue';
import { MESSAGES } from '@/util/message-notification';

const state = () => ({
  loading: false,
  api: ConstantAPI.dashboard.temporaryAdd,
  dataTable: null,
  dataChart: [],
  xAxis: null,
  yAxis: null,
  total: 0,
});

const actions = {
  async getAll({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_ALL_BY_CONDITION,
        null,
        params
      );
      checkResponse(response, () => {
        state.dataTable = response.data.DataTable;
        state.dataChart = response.data.DataChart.data;
        let data = 0;
        if (state.dataTable) {
          state.dataTable.map((e) => {
            data += e.soLuong;
          });
          state.total = data
        } else {
          state.total = 0;
        }
      });
    } catch (err) {
      console.log(err);
    }
  },
  async export({ state }, params) {
    try {
      state.loading = true;
      notification.info({
        message: 'Thông báo',
        description: MESSAGES.MESSAGES_0033,
        duration: 4,
      });
      const response = await DataService.getConfig(
        state.api.EXPORT.url,
        params,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `dia-chi-tam-tru-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: MESSAGES.MESSAGES_0024,
        duration: 4,
      });
      state.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
