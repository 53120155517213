import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/config-criteria/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    api: ConstantAPI.configCriteria,
    visible: false,
    action: null,
    type: null,
    listAllCanBo: [],
    listAllCanBoShow: [],
    listCBShowHandle: [],
    tongHsMoi: 0,
    tongHsPc: 0,
});

const actions = {
    async getAll({commit, state}) {
        try {
            commit('setLoading', true);
            state.listAll = []
            const response = await DataService.callApi(state.api.GET_ALL);
            checkResponse(response, () => {
                commit('setAll', response.data);
            });
            return response.data
        } catch (err) {
            console.log(err);
        }
    },
    async create({commit, state}, payload) {
        try {
            let isSuccess = false;
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.CREATE, payload);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGES_0022,
                    duration: 4,
                });
                isSuccess = true;
            });
            commit('setLoading', false);
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async getAllCanBo({commit, state}, params) {
        try {
            commit('setLoading', true)
            const response = await DataService.callApi(state.api.GET_ALL_CAN_BO, null, params);
            checkResponse(response, () => {
                state.listAllCanBo = response.data || []
                state.listAllCanBoShow = response.data.map(e => {
                    const isHasCB = state.listAll.find(i => e.idCB === i.idCanBo)
                    return isHasCB ? ({
                        id: null,
                        idCanBo: isHasCB.idCanBo,
                        locale: isHasCB.locale,
                        image: isHasCB.image,
                        maCanBo: isHasCB.maCanBo,
                        maQT: isHasCB.maQT,
                        maTC: isHasCB.maTC,
                        soLuong: isHasCB.soLuong,
                        tenCanBo: isHasCB.tenCanBo,
                        trongSo: isHasCB.trongSo,
                        tongHsDuocPc: isHasCB.tongHsDuocPc,
                        checked: true
                    }) : ({
                        id: null,
                        idCanBo: e.idCB,
                        locale: '',
                        image: e.image,
                        maCanBo: e.maCB,
                        maQT: [],
                        maTC: [],
                        soLuong: 0,
                        tenCanBo: e.tenCB,
                        trongSo: 0,
                        tongHsDuocPc: e.tongHsDuocPc,
                        checked: false
                    })
                }) || []
            });
            state.listCBShowHandle = state.listAllCanBoShow
            commit('setLoading', false)
            return state.listAllCanBo || []
        } catch (err) {
            console.log(err);
        }
    },
    async getHoSoMoiTheoNgay({commit, state}, params) {
        try {
            commit('setLoading', true)
            const response = await DataService.callApi(state.api.GET_HS_THEO_NGAY, null, params);
            checkResponse(response, () => {
                state.tongHsMoi = response.data.tongHsMoi
                state.tongHsPc = response.data.tongHsPc
            });
            commit('setLoading', false)
        } catch (err) {
            console.log(err);
        }
    },
    onChangeCanboByDate({state}) {
        if (state.listAll.length) {
            state.listAll.forEach(e => {
                const canBo = state.listAllCanBo.find(i => e.idCanBo === i.idCB)
                if (canBo) {
                    e.tongHsDuocPc = canBo.tongHsDuocPc
                }
            })
        }
    },
    onSearchCanBo({state}, keyword) {
        if (!keyword) {
            state.listAllCanBoShow = state.listCBShowHandle
        } else {
            state.listAllCanBoShow = state.listCBShowHandle.filter(e => e.tenCanBo.toLowerCase().includes(keyword.toLowerCase())
                || e.maCanBo.toLowerCase().includes(keyword.toLowerCase()))
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
